import * as React from "react";
import { Profile, Project } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import Moment from "moment";
import MailchimpActivity from "../../Components/MailchimpActivity";
import { SoilTypes, PaletteTypes } from "../../Interfaces";
import {
  Typography,
  useMediaQuery,
  makeStyles,
  Button,
  Grid,
  FormControl,
  TextField,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Checkbox,
  Box,
} from "@material-ui/core";
import DesignProfileInfo from "./DesignProfileInfo";
import ProfileFeatures from "../ProfileFeatures";
import { UpdateClientInfo } from "./UpdateClientInfo";
import Contacts from "../../Components/Contacts";
import ProjectManagerDisplay from "./ProjectManagerDisplay";
import { ClientNotes } from "./ClientNotes";
import AssignmentInfo from "./AssignmentInfo";
import UpdateClientEmail from "./UpdateClientEmail";
import { useInstallPlansForProject } from "../../util/hooks";
import { IProjectInstallPlans } from "../../util/hooks/useInstallPlansForProject";
import { SubmitMaterialsForClientModal } from "./SubmitMaterialsForClientModal/SubmitMaterialsForClientModal";
import { showProMatchProjectTags } from "../../util/functions/showProMatchProjectTags";
import { BudgetDisplayWrapper } from "../../../src/Components/Budget/BudgetDisplayWrapper";
import { PipefyCardLinksAndCreate } from "./PipefyCardLinksAndCreate";
import { BuildIntentResponseCard } from "src/Components/BuildIntentResponseCard";

export interface Props {
  clientRecord: Profile;
  clientProject: Project;
  fetchProfile: (clientId: string) => void;
  permissions: Object;
  designProfile: Object;
  history: any;
  isEmployee: boolean;
  isUserDisabled: boolean;
  handleSetClientRecord: CallableFunction;
  handleDisabledStatusChange: () => void;
}

const inputFields = [
  { id: "upworkName", text: "Upwork Name", type: "input" },
  { id: "hardinessZone", text: "Hardiness Zone", type: "input" },
  { id: "designArea", text: "Design Area", type: "input" },
  { id: "delighted", text: "Sent Delighted Email", type: "checkbox" },
  { id: "conciergeHours", text: "Concierge Hours", type: "input" },
  { id: "soilType", text: "Soil Type", type: "select", options: SoilTypes },
  {
    id: "paletteType",
    text: "Palette Type",
    type: "select",
    options: PaletteTypes,
  },
];

const installQuestionsMap = {
  installPhases: "Do you plan to install all at once or in phases?",
  budget: "What is your budget right now? Long-term budget?",
  immediatePriorities:
    "What elements or areas in your design are your immediate priorities?",
  idealTimeline:
    "What is your ideal timeline for the first phase? How about the full design?",
};

const useStyles = makeStyles((theme) => ({
  splitContainer: {
    display: "flex",
    flexFlow: "row nowrap",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column nowrap",
    },
  },
}));

function ClientDetailTab(props: Props) {
  const permissions = props.permissions;
  // @ts-ignore
  const enabled = permissions["profile"]["access"] === "update";
  const clientRecord = props.clientRecord;
  const designProfile = props.designProfile;
  // @ts-ignore
  const scid = clientRecord["stripeChargeId"];
  // @ts-ignore
  const soid = clientRecord["squarespaceOrderId"];
  const classes = useStyles();

  const smDown = useMediaQuery((theme) =>
    (theme as any).breakpoints.down("sm")
  );

  const [mailchimpActivityOpen, setMailchimpActivityOpen] =
    React.useState<boolean>(false);

  const [
    clientProjectInstallPlans,
    loadingInstallPlans,
    errorGettingInstallPlansMessage,
  ] = useInstallPlansForProject(
    props.clientProject.id,
    props.clientRecord.userId
  );

  const toggleAnnotationState = async (
    field: string | string[],
    val: boolean
  ) => {
    let updateObj = {};

    if (Array.isArray(field)) {
      field.forEach((field) => {
        // @ts-ignore
        updateObj[field] = val;
      });
    } else {
      // @ts-ignore
      updateObj[field] = val;
    }

    props.handleSetClientRecord({ ...clientRecord, ...updateObj });

    await firebase
      .firestore()
      .collection("profiles")
      .doc(clientRecord.id)
      .set(updateObj, { merge: true });
  };

  return (
    <React.Fragment>
      <div style={{}}>
        <Box display="flex" flexDirection="row">
          <Box>
            <div style={{ display: "flex" }}>
              <div>
                <UpdateClientInfo
                  isEmployee={props.isEmployee}
                  clientRecord={clientRecord}
                  fetchProfile={props.fetchProfile}
                />

                <Typography>
                  Stripe Charge ID:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {scid || "No Stripe charge ID"}
                  </span>
                </Typography>
                <Typography>
                  Order ID:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {soid || "No order ID"}
                  </span>
                </Typography>
                <Typography>
                  Referral Source:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {!!clientRecord.referralSource
                      ? clientRecord.referralSource
                      : "not given"}
                  </span>
                </Typography>
                <SubmitMaterialsForClientModal clientProfile={clientRecord} />
              </div>

              {!!!soid && !!!scid && (
                <div>
                  <Typography paragraph>Order NOT confirmed</Typography>
                </div>
              )}
              {
                // @ts-ignore
                permissions["profile"]["access"] === "update" && (
                  <div style={{ marginLeft: "1.5rem" }}>
                    <UpdateClientEmail clientRecord={clientRecord} />
                    <Typography>
                      {clientRecord.wizardDone
                        ? `Submitted materials ${Moment(
                            clientRecord.wizardDone
                          ).fromNow()}`
                        : "Has not submitted materials"}
                    </Typography>
                    <Typography>
                      Install type:{" "}
                      {clientRecord.installType
                        ? (() => {
                            if (!clientRecord.installType) return;
                            const map = {
                              allAtOnce: "all at once",
                              phases: "in phases",
                            };
                            // @ts-ignore
                            return map[clientRecord.installType];
                          })()
                        : "Did not respond"}
                    </Typography>
                    <Typography>
                      Contractor Preference:{" "}
                      {showProMatchProjectTags(props.clientProject.tags)
                        .length > 0
                        ? showProMatchProjectTags(props.clientProject.tags)
                            .join(", ")
                            .toString()
                        : getProjectInstallPlanMessage(
                            clientProjectInstallPlans
                          )}
                    </Typography>
                    {clientRecord["airProfile"] &&
                    // @ts-ignore
                    !!clientRecord["airProfile"]["phone"] ? (
                      <Typography paragraph>
                        Phone
                        {
                          // @ts-ignore
                          clientRecord["airProfile"] &&
                            // @ts-ignore
                            clientRecord["airProfile"]["phone"]
                        }
                      </Typography>
                    ) : null}
                    {
                      // We need this check otherwise one of the hooks used by this
                      // this component will throw an error, because it is always
                      // expecting a value for the projectId, but our projectId could
                      // be undefined. The typings suggest that it will never
                      // be undefined, but at runtime it can initially be.
                      props.clientProject.id && (
                        <BudgetDisplayWrapper
                          projectId={props.clientProject.id}
                          packageType={props.clientRecord.package}
                          yardDifficultyRating={
                            props.clientProject.yardDifficultyRating
                          }
                          clientBudgetIntent={
                            props.clientProject.clientBudgetIntent
                          }
                        />
                      )
                    }
                  </div>
                )
              }
              <BuildIntentResponseCard project={props.clientProject} />
            </div>
            <ProjectManagerDisplay
              profile={props.clientRecord as any}
              type="CDM"
            />
            <ProjectManagerDisplay
              profile={props.clientRecord as any}
              type="PRIMARY_CONTACT"
            />
            <ProjectManagerDisplay
              profile={props.clientRecord as any}
              type="QA"
            />
          </Box>
          <Box p={1} ml={2}>
            <AssignmentInfo clientRecord={props.clientRecord} />
          </Box>
        </Box>
        <Contacts profileId={clientRecord.id} />
        <div className={classes.splitContainer}>
          {!!Object.keys(designProfile).length ? (
            <DesignProfileInfo designProfile={designProfile} />
          ) : null}

          <div style={{ marginLeft: smDown ? "0" : "2rem" }}>
            {clientRecord.installQuestions && (
              <>
                <Typography variant="h6">
                  Install-this-design answers:
                </Typography>
                {Object.entries(clientRecord.installQuestions).map(
                  ([title, val]) => {
                    return (
                      <div>
                        <Typography>
                          {
                            // @ts-ignore
                            installQuestionsMap[title]
                          }
                        </Typography>
                        <TextField
                          fullWidth
                          variant="outlined"
                          multiline
                          value={val}
                          InputProps={{ readOnly: true }}
                        >
                          {val}
                        </TextField>
                      </div>
                    );
                  }
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div style={{ padding: 16 }}>
        <Grid container>
          {inputFields.map((field) =>
            // @ts-ignore
            permissions["profile"]["fields"] &&
            // @ts-ignore
            field.id in permissions["profile"]["fields"] &&
            // @ts-ignore
            permissions["profile"]["fields"][field.id] === "hidden" ? (
              <div></div>
            ) : (
              <Grid item>{getFormComponent(field, enabled)}</Grid>
            )
          )}
        </Grid>
        <PipefyCardLinksAndCreate
          profile={props.clientRecord}
          project={props.clientProject}
        />
        <ClientNotes clientRecord={props.clientRecord} />

        <div style={{ paddingBottom: 25 }}>
          <Button
            variant="contained"
            onClick={() => setMailchimpActivityOpen(true)}
          >
            See Email Activity
          </Button>
          <MailchimpActivity
            open={mailchimpActivityOpen}
            onCloseClick={() => {
              setMailchimpActivityOpen(false);
            }}
            email={clientRecord["email"] || ""}
          />
        </div>

        <ProfileFeatures
          profile={clientRecord}
          isUserDisabled={props.isUserDisabled}
          toggleProfileFlag={toggleAnnotationState}
          onCheckboxChange={(e) =>
            onProfileFeatureCheckboxChange(e, e.target.checked)
          }
          handleDisabledStatusChange={props.handleDisabledStatusChange}
        />
      </div>
    </React.Fragment>
  );

  function getFormComponent(field: any, enabled: boolean) {
    var Control: any;

    if (field.type === "checkbox") {
      Control = (
        <FormControlLabel
          key={clientRecord.id}
          label={field.text}
          control={
            <Checkbox
              id={field.id}
              name={field.id}
              checked={
                // @ts-ignore
                clientRecord[field.id]
              }
              onChange={(e) => onFieldChange(e)}
              disabled={!enabled}
            />
          }
        />
      );
    } else if (field.type === "select") {
      Control = (
        <div>
          <InputLabel htmlFor="a">{field.text}</InputLabel>
          <Select
            name={field.id}
            id={field.id}
            value={
              // @ts-ignore
              clientRecord[field.id]
            }
            onChange={(e) => onFieldChange(e)}
            input={<Input id={field.id} name={field.id} />}
            autoWidth
            fullWidth
            style={{ minWidth: 120 }}
            readOnly={!enabled}
          >
            <MenuItem value="" key="first">
              <em>None</em>
            </MenuItem>
            {Object.keys(field.options).map((value) => (
              <MenuItem value={value} key="second">
                {field.options[value]}
              </MenuItem>
            ))}
          </Select>
        </div>
      );
    } else if (
      field.id == "hardinessZone" &&
      clientRecord["airProfile"] != null
    ) {
      // @ts-ignore
      let zone = clientRecord["airProfile"]["zone"];

      Control = (
        <div>
          <InputLabel htmlFor={field.id}>{field.text}</InputLabel>
          <Input
            key={clientRecord.id}
            id={field.id}
            name={field.id}
            placeholder=""
            value={zone}
            inputProps={{
              autoCapitalize: "off",
              autoCorrect: "off",
              spellCheck: false,
            }}
            onChange={(e) => onFieldChange(e)}
            readOnly={!enabled}
          />
        </div>
      );
    } else {
      Control = (
        <div>
          <InputLabel htmlFor={field.id}>{field.text}</InputLabel>
          <Input
            key={clientRecord.id}
            id={field.id}
            name={field.id}
            placeholder=""
            value={
              // @ts-ignore
              clientRecord[field.id]
            }
            inputProps={{
              autoCapitalize: "off",
              autoCorrect: "off",
              spellCheck: false,
            }}
            onChange={(e) => onFieldChange(e)}
            readOnly={!enabled}
          />
        </div>
      );
    }

    return (
      <FormControl margin="normal" error={false ? true : false}>
        {Control}
      </FormControl>
    );
  }

  /**
   * Gets a string to display from the client's install plans.
   * @param projectInstallPlans Contains the client's response to the install plan questions in onboarding and post-v2 DSAT survey.
   * @returns A message indicating the client's install plans.
   */
  function getProjectInstallPlanMessage(
    projectInstallPlans: IProjectInstallPlans
  ): string {
    if (loadingInstallPlans) {
      return "Loading install plans...";
    }

    if (errorGettingInstallPlansMessage) {
      return errorGettingInstallPlansMessage;
    }

    const maybeWorkWithYardzenMessage = "Maybe want to work with YZ contractor";

    const willWorkWithYardzenMessage = "Definitely want to work with YZ";

    const installPlanMessageMap = new Map<string, string>([
      ["", "Did not respond"],
      ["yardzenContractor", willWorkWithYardzenMessage],
      ["ownContractor", "Already have their own contractor"],
      ["DIY", "Plans to DIY"],
      ["learnMoreAboutProMatch", maybeWorkWithYardzenMessage],
      ["No", "Client already has their own build plans"],
      ["Maybe", maybeWorkWithYardzenMessage],
      ["Yes", willWorkWithYardzenMessage],
    ]);

    if (projectInstallPlans.postV2InstallPlan) {
      return (
        installPlanMessageMap.get(projectInstallPlans.postV2InstallPlan) ??
        "Unknown"
      );
    }

    return (
      installPlanMessageMap.get(projectInstallPlans.onboardingInstallPlan) ??
      "Unknown"
    );
  }

  function onProfileFeatureCheckboxChange(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    const flag = event.target.value;
    let enabledFeatureFlags = clientRecord["featureFlags"] || [];

    if (checked && !enabledFeatureFlags.includes(flag)) {
      enabledFeatureFlags.push(flag);
    } else if (!checked && enabledFeatureFlags.includes(flag)) {
      enabledFeatureFlags.splice(enabledFeatureFlags.indexOf(flag), 1);
    }

    clientRecord["featureFlags"] = enabledFeatureFlags;

    props.handleSetClientRecord(clientRecord);

    firebase
      .firestore()
      .collection("profiles")
      .doc(clientRecord.id)
      .set({ featureFlags: enabledFeatureFlags }, { merge: true })
      .then((result) => {})
      .catch((reason) => {});
  }

  function onFieldChange(e: any) {
    let docData = {};

    if (e.target.name === "delighted") {
      // @ts-ignore
      docData[e.target.name] = e.target.checked;
      // @ts-ignore
      clientRecord[e.target.name] = e.target.checked;
    } else {
      // @ts-ignore
      docData[e.target.name] = e.target.value;
      // @ts-ignore
      clientRecord[e.target.name] = e.target.value;
    }

    props.handleSetClientRecord(clientRecord);

    firebase
      .firestore()
      .collection("profiles")
      .doc(clientRecord.id)
      .set(docData, { merge: true })
      .then((result) => {})
      .catch((reason) => {});
  }
}

export default ClientDetailTab;
